<template>
    <div>

        <el-card class="box-card mb10">
            <el-form :inline="true" :model="queryinfo" label-suffix="：" class="queryinfo">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="预警类型">
                            <el-input v-model="queryinfo.user" placeholder="请输入预警类型"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="预警阈值">
                            <el-input v-model="queryinfo.user" placeholder="请输入预警阈值"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="开始日期">
                            <el-date-picker v-model="queryinfo.date" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                type="datetime" placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="结束日期">
                            <el-date-picker v-model="queryinfo.date" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                type="datetime" placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="searchorrestbutton">
                    <el-button type="primary" class="searchbutton" size="small" icon="el-icon-search">搜索</el-button>
                    <el-button class="resetbutton" size="small" icon="el-icon-refresh-left">重置</el-button>
                </div>
            </el-form>
        </el-card>
        <el-card class="box-card">
            <div class="mb10">
            </div>
            <!-- 搜索与添加区域 -->
            <el-table header-align="center" :data="tableData" style="width: 100%" :border="true" max-height="600px"
                size="mini">
                <el-table-column prop="createTimeStr" label="报警日期"></el-table-column>
                <el-table-column prop="type" label="报警类型"/>
                <el-table-column prop="name" label="通知人员"> </el-table-column>
                <el-table-column prop="area1Days" label="本月春城优良天数"> </el-table-column>
                <el-table-column prop="area2Days" label="旺庄优良天数"> </el-table-column>

            </el-table>
            <!-- 分页区域 -->
            <!-- <span class="demonstration">完整功能</span> -->
            <el-pagination
			  @size-change="handleSizeChange"
			  @current-change="handleCurrentChange"
			  :current-page="queryinfo.index"
			  :page-sizes="[1, 10, 20, 30]"
			  :page-size="queryinfo.pageSize"
			  layout="total, sizes, prev, pager, next, jumper"
			  :total="total"
			>
            </el-pagination>
        </el-card>
    </div>
</template>
<script>
import { getWarningLog } from "@/utils/masterdata.js";

export default {
    data() {
        return {
            id: "",
            centerDialogVisible: false,
            inputstates: false,
            addobtntate: "添加",
            queryinfo: {
                index: 1,
                pageSize: 10,

            },
            userList: [],
            total: 0,
            tableData: [],
        };
    },

    mounted() {
        this.getWarningLog()
    },
    methods: {
        async getWarningLog() {
            const { body } = await getWarningLog({ ...this.queryinfo });
            this.tableData = body.list
            this.total = body.count
        },

        // 监听pageSize的改变
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.queryinfo.pageSize = val;
            this.getUserList();
        },
        // 监听页码值改变的事件
        handleCurrentChange(val) {
            this.queryinfo.index = val;
            console.log(`当前页: ${val}`);
        },

    },
};
</script>
